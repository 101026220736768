import full_team from './full_team.jpg';
import ross_profile from './ross.png';
import angela_profile from './angela.jpg';
import jennifer_profile from './jennifer.jpg';
import idris_profile from './idris.jpg';
import kate_profile from './kate.jpg';
import letian_profile from './letian.jpg';
import sunil_profile from './sunil.jpg';
import brian_profile from './brian.jpg';

import nelson_profile from './nelson.jpg';
import yang_profile from './yang.jpg';
import consulting_team from './consulting_outline.png';
import teaching_team from './teaching_outline.png';
import nelson_outline from './nelson_outline.png';
import angela_outline from './angela_outline.png';
import ross_outline from './ross_outline.png';
import yang_outline from './yang_outline.png';
import coda_outline from './coda_outline.png';


export { 
  full_team,
  ross_profile,
  angela_profile,
  jennifer_profile,
  idris_profile,
  yang_profile,
  kate_profile,
  letian_profile,
  nelson_profile,
  consulting_team,
  teaching_team,
  nelson_outline,
  angela_outline,
  yang_outline,
  ross_outline,
  sunil_profile,
  brian_profile,
  coda_outline
}