import React from 'react';

import styles from './team.module.scss';

import Layout from '../../components/layout';

import {
  ross_profile,
  angela_profile,
  jennifer_profile,
  idris_profile,
  kate_profile,
  letian_profile,
  sunil_profile,
  nelson_profile,
  yang_profile,
  brian_profile,
  coda_outline,
} from '../../images/team';

export default class TeamMain extends React.Component {

  render() {

    return(
      <Layout>
      <div className={styles.teamPage}>
        <div className={styles.banner}>
          <p className={styles.title}></p>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.header}>Our Team</div>
          <div className={styles.description}>At Blueprint, we think our team represents our greatest strength, with some of the most talented and experienced instructors and counselors around. To help you get to know us a bit better, we asked ourselves to answer some challenging questions about education and the role that it has played in our own lives. Read on to hear our responses.</div>
        </div>
      <div className={styles.bioSection}>
        <div className={styles.bio}>
          <div className={styles.imageWrapper}>
            <div className={styles.imageWrapperTwo}>
              <img alt='team member profile' src={idris_profile}/>
            </div>
            <div className={styles.titleWrapper}>
              <div className={styles.name}>Idris McClain</div>
              <div className={styles.position}>Director, Boarding School Consulting</div>
              <div className={styles.exp}>Former Admissions Officer, Exeter Academy</div>
            </div>
          </div>
          <div className={styles.textWrapper}>
            <p className={styles.header}>Why are you passionate about education?</p>
            <p>I've been an educator for more than 11 years now.  I was afforded the opportunity to learn from and serve one of the most well-known boarding schools in the world, where I worked to find some of America's best and brightest students.  I learned that educators can change the trajectory of a young person's life no matter what their background may be; socio-economic, ethnic, and geographic locations all make us the people we are and make us unique.  Everyone deserves an opportunity to become successful and being able to play a part in students' success is what keeps me thrilled to continue to provide guidance to these great kids.</p>
          </div>
        </div>
        <div className={styles.bio}>
          <div className={styles.imageWrapper}>
            <div className={styles.imageWrapperTwo}>
              <img alt='team member profile' src={ross_profile}/>
            </div>
            <div className={styles.titleWrapper}>
              <div className={styles.name}>Ross Gray</div>
              <div className={styles.position}>Senior Test Prep Instructor</div>
              <div className={styles.exp}>M.A., University of Toronto</div>
            </div>
          </div>
          <div className={styles.textWrapper}>
            <p className={styles.header}>Can you reflect on significant failure/challenge?</p>
            <p>We often have fears about being a beginner at something. It makes us feel insecure and vulnerable. But my education has taught me that it is those very moments when we the beginner that can become the richest ground for the cultivation of learning and growth.</p>
          </div>
        </div>
        <div className={styles.bio}>
          <div className={styles.imageWrapper}>
            <div className={styles.imageWrapperTwo}>
              <img alt='angela profile image' src={angela_profile}/>
            </div>
            <div className={styles.titleWrapper}>
              <div className={styles.name}>Angela Kong</div>
              <div className={styles.position}>College Admissions Consultant</div>
              <div className={styles.exp}>B.A., Colorado College</div>
            </div>
          </div>
          <div className={styles.textWrapper}>
            <p className={styles.header}>What is the one piece of advice you have for students preparing for college in the US?</p>
            <p>Take a leap of faith.</p>
            <p>During my senior year of high school, when I heard back from the final college on my list, I was waitlisted. Expecting that this school was a sure target, I was shocked. I was forced to take ask myself - do I want to stay in state, or take a risk and leave California (gasp! is right)? So I leapt.</p> 
            <p>I think about this Tibetan proverb often: "On every journey, you must die once. The person who arrives should not be the person who leaves." I believe that taking a leap of faith gives us room to grow beyond how we ever envisioned ourselves. Yet with each decision, I've become braver and more resilient to the challenges I have faced, a stronger person than I ever was when I left California.</p>
          </div>
        </div>
        <TeamMemberBio 
          imageSrc={yang_profile}
          imageSrcAlt="yang profile image" 
          name='Yang Li'
          position='Director of Education'
          exp='B.A., Harvard University'
          question="Why are you passionate about education?"
          response={["I'm a firm believer in Napoleon's quote, “Glory may be fleeting, but obscurity is eternal.” I love teaching because I can encourage my students to seek their own glory – to do something different that leaves a mark on the world – in the short time we all have on Earth.   My passion for education is not just about imparting knowledge, but also about inspiring in my students a genuine curiosity about the world and a drive to improve it.", <br />, <br />, "In my teaching, I love zooming out from a mundane test problem to show the greater context of literature or science, to give them a view of why all of it is actually fascinating and important.  I want my students to love knowledge – about history, science, and world affairs - as I do, and I want them to believe that they can use their knowledge from diverse fields do something gloriously new."]}
        />
        <TeamMemberBio 
          imageSrc={jennifer_profile}
          imageSrcAlt="jennifer profile image" 
          name='Jennifer Chang'
          position='Senior Test Prep Instructor'
          exp='B.A., University of Texas, Austin'
          question="What is the best advice you've ever gotten?"
          response={["This too shall pass.", <br />, <br />, "Sure, it’s a mantra that I’ve invoked whenever I can’t take it anymore - “it” being finals week, a heart-wrenching breakup, even a tiresome conversation. But more recently, I’ve used this advice to remind myself of the flip side: even the good things will eventually come to an end. I need to put my screen down and appreciate a slow conversation over a cup of coffee or a game of tag with my niece or something as mundane as a pair of warm socks on a cold day. All these need to be savored, because this too shall pass."]}
        />
        <TeamMemberBio 
          imageSrc={kate_profile}
          imageSrcAlt="kathryn profile image" 
          name='Kathryn Hill'
          position='College Admissions Consultant'
          exp='B.A., College of the Holy Cross'
          question="Can you reflect on a significant failure/challenge?"
          response="I’m frequently asked about my unique accent. It’s partly from living in many different areas of the US and morphing the various local accents into my own speech, and partly from the residue of articulation disorder, a condition that makes it physically challenging to form words.  After a decade of little success with speech therapy, I turned to the high school speech and debate team for help.  Through determination and hours of practice each day, my speech not only became intelligible, but I also qualified for the national competition all four years.  I still have this drive to overcome the “seemingly impossible.”  In college I doubled my course load to successfully major and minor in a variety of subjects and currently, I enjoy physically challenging myself by completing a 100-250km ultramarathon every few months."
        />
        <TeamMemberBio 
          imageSrc={nelson_profile}
          imageSrcAlt="nelson profile image" 
          name='Nelson Urena'
          position='Director, College Counseling Department'
          exp='Undergraduate Admissions Officer / Assistant Director of Multicultural Recruitment, Cornell University'
          question="What inspires you?"
          response={["I’m momentarily cross eyed as I attempt to conceptualize quantumly entangled photons. My mind races with flash forwards of exponentially increased computing powers, instant communication with worlds outside our Solar System, and a holographic universe parallel to our own…", <br />, <br />,  "My skin tingles, my head rocks back and forth and my larynx emits a deep groaning sound of approval as I listen to DJ Kool Herc’s narrative. He tells his rendition of a moment hailed as the genesis of a genre. His discovery of a technique on the turntables which set aflame an entire culture…", <br />, <br />, "I find inspiration in stories. The verbal, written, visual or musical accounts of adventures, refusals and trials, failures and triumphs, of heroes and foes, of troubles and woes, of exuberance, of laments."]}
        />
        <TeamMemberBio 
          imageSrc={letian_profile}
          imageSrcAlt="letian profile image" 
          name='Dr. Letian Xie'
          position='Senior Test Prep Instructor, Math & Science Specialist'
          exp='PhD, Biochemistry, University of California, Los Angeles'
          question="Why did you choose to major in Biochemistry?"
          response="“What major I should choose?” That was one of the most common question I heard from my students when I was teaching in Colleges. I always replied, “Find your true interest and you will be best off.” This was a piece of advice that I received from Dr. Cantrill, my freshmen year organic chemistry professor at UCLA. In fact, I took that piece of advice by heart and switched my major three times to find my own true interest. I started college as an Electircal Engineering major, but changed to Pre-Med after 6 months of an unexciting internship at NASA’s Jet Propulsion Laboratory (JPL).  Another 6 months interning at the Huntington Memorial Hospital changed my mind and I then decided to major in in Biophysics. Eventually I settled with Biochemistry and Molecular Biology after enjoying doing research in a biochemistry lab where I went on to do my PhD study."
        />
        <TeamMemberBio 
          imageSrc={sunil_profile}
          imageSrcAlt="sunil profile image" 
          name='Sunil Pticek-Damle'
          position='Senior Admissions Consultant'
          exp='B.A., Occidental College'
          question="What is one piece of advice you have for students preparing for college in the US?"
          response={["Be yourself. Trust the process.", <br />, <br />, "I often meet students who feel the need to fit into a specific “mold” when they are applying to college. I think this is the biggest mistake prospective college students can make. I view the college preparation process as one of the most important moments of personal growth in your life (it certainly was for me!), so not being authentic will rob you of the chance to truly learn more about who you are—what you like, what you dislike, and where you should be headed.",<br />, <br />, "To that end, trust the process! Even when it may seen overwhelming, or you may feel completely lost, trust that, if you remain honest with yourself, you will come out on the other side happier and with more clarity about your future. So, stay true to who you are and the rest will take care of itself." ]}
        />
        <TeamMemberBio 
          imageSrc={brian_profile}
          imageSrcAlt="brian profile image" 
          name='Brian Wang'
          position='Founder and CEO, Blueprint International'
          exp='J.D., University of California, Hastings College of the Law'
          question="Why are you passionate about education?"
          response="Growing up, my parents knew very little about the US education system and I didn’t have great access to good mentorship. As a result, I made a lot of mistakes in my college preparation process, like waiting to the last minute to write my essays and assuming I could rely on my SAT score. I’m in education because I don’t want to see students make the same mistakes I did when I was in high school. I want to make sure that every student is aware of all the possible options they have at their disposal and that they are approaching the process in the right way. I’m passionate about education because there is no greater satisfaction than seeing one my students achieve their dreams."
        />
        <TeamMemberBio 
          imageSrc={coda_outline}
          imageSrcAlt="coda profile image" 
          name='Coda Hu'
          position='Senior Test Prep Instructor, Writing Specialist'
          exp='B.A., University of Chicago'
          question="Why did you decide to attend the University of Chicago?"
          response="The University of Chicago prides itself on its eclectic essay writing prompts, many provided by alumni of the school. I vividly remember the essay prompt I chose: “the mind that does not stick.” The way the question was presented made me feel comfortable enough to show my nerdy, quirky, vulnerable side in my essay, which adapted one of my blog entries on Virginia Woolf’s Mrs. Dalloway as a response. While writing the essay, I dreamed of finding a real-life environment similar to my online community, where I could meet like-minded students whose idea of a good time was debating Marx or Freud."
        />
    </div>
  </div>
  </Layout>
    ) 
  }
}

const TeamMemberBio = function(props) {
  return(
    <div className={styles.bio}>
      <div className={styles.imageWrapper}>
        <div className={styles.imageWrapperTwo}>
          <img alt={props.imageSrcAlt} src={props.imageSrc}/>
        </div>
        <div className={styles.titleWrapper}>
          <div className={styles.name}>{props.name}</div>
          <div className={styles.position}>{props.position}</div>
          <div className={styles.exp}>{props.exp}</div>
        </div>
      </div>
      <div className={styles.textWrapper}>
        <p className={styles.header}>{props.question}</p>
        <p>{props.response}</p>
      </div>
    </div>
  )
}